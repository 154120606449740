<template>
    <div class="Materials">
        <div class="Materials__container">
            <div style="font-size: 12px; color: #969799;">点击可继续上传实名材料</div>
            <div class="Materials__body">
                <my-scroll-list ref="list" :option="option">
                    <template #item="{item}">
                        <div class="Materials__item Item" @click="toDetail(item)">
                            <div class="Item__container">
                                <div class="Item__title">
                                    <span style="font-size: 16px; font-weight: 600;">{{ item.batchName }}</span>
                                    <span style="font-size: 12px; color: #3C8FFF; margin-left: 4px;">
                                        {{ item.statusAudited | getMapText('number/materialAuditStatus') }}
                                    </span>
                                </div>
                                <div class="Item__desc">
                                    <div class="Item__descLabel">企业名称</div>
                                    <div class="Item__descValue">{{ item.companyName }}</div>
                                </div>
                                <div class="Item__desc">
                                    <div class="Item__descLabel">保存时间</div>
                                    <div class="Item__descValue">{{ item.createTime }}</div>
                                </div>
                            </div>
                            <van-icon v-if="!['1','2','3'].includes(item.statusAudited)" :size="12" color="#8F9295" name="arrow" />
                        </div>
                    </template>
                </my-scroll-list>
            </div>
        </div>
        <div class="Materials__search">
            <van-search v-model="option.query.batchName" placeholder="请输入400号码，支持模糊搜索" @search="onSearch" />
        </div>
        <router-view />
    </div>
</template>

<script>
    import {MyScrollList} from "@/components/MyPlugins";
    import {numHoldApply} from "@/api/openOrder";

    export default {
        name: "Materials",
        components: {MyScrollList},
        data() {
            return {
                option: {
                    api: numHoldApply,
                    query: {
                        batchName: '',
                    }
                },
            }
        },
        methods: {
            onSearch() {
                this.$refs.list.onSearch();
            },
            toDetail(item) {
                let {statusAudited, batchCode, batchName, mmfCode} = item;
                if (!['1','2','3'].includes(statusAudited)) {
                    this.$router.push({path: 'materials/detail', query: {batchCode, batchName, mmfCode}});
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .Materials {
        height: 100vh;
        background: #F7F8FA;
        display: flex;
        flex-direction: column;
        &__container {
            flex: 1;
            padding: 10px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        &__body {
            flex: 1;
            overflow: auto;
            margin-top: 10px;
        }
        &__item {
            border-radius: 4px;
            background: #FFFFFF;
            margin-bottom: 10px;
            padding: 16px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .Item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__title {
                line-height: 30px;
                margin-bottom: 4px;
                display: flex;
                align-items: center;
            }
            &__desc {
                display: flex;
                font-size: 12px;
                gap: 0 8px;
                line-height: 18px;
                margin-bottom: 4px;
                &:last-child {
                    margin-bottom: 0;
                }
                &Label {
                    width: 48px;
                    color: #969799;
                }
                &Value {
                    flex: 1;
                    color: #323233;
                }
            }
        }
    }
</style>
